import { render, staticRenderFns } from "./LoopTable.vue?vue&type=template&id=b0ee08a6&scoped=true&"
import script from "./LoopTable.vue?vue&type=script&lang=ts&"
export * from "./LoopTable.vue?vue&type=script&lang=ts&"
import style0 from "./LoopTable.vue?vue&type=style&index=0&id=b0ee08a6&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0ee08a6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
