













import Vue from 'vue';

import { debounce } from 'lodash';

export default Vue.extend({
  props: ['value'],
  data: () => {
    return {
      search: '',
    };
  },
  methods: {
    handleFocus() {
      this.$el.classList.toggle('elevation-5');
      this.$emit('focus');
      const icon = this.$refs.icon as Vue;
      icon.$el.classList.toggle('dn-search-bar__icon--active');
    },
    update: debounce(function (this: any) {
      this.$emit('input', this.search);
    }, 200),
  },
});
