


























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LoopTable extends Vue {
  @Prop() loops!: any[];
  @Prop() current!: any;
  @Prop() playing!: boolean;
  @Prop({ default: false }) sample!: boolean;
  @Prop({ default: false }) canDownload!: boolean;

  handleLoopClick(loop: any) {
    if (this.sample) {
      this.$emit('sample:click', loop);
    } else {
      this.$emit('loop:click', loop);
    }
  }

  handleAddLoop(loop: any) {
    this.$emit('loop-add', loop);
  }

  showPause(loop: any) {
    return this.sample
      ? this.playing
      : this.current._id === loop._id && this.playing;
  }
}
